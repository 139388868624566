<template>
  <div class="event-list">
    <h3>Events</h3>
    <div class="event-events">
      <div
        v-for="event in events"
        :key="event.id"
        class="event-event"
        :class="{
          selected: isEventSelected(event)
        }">
        <a href="#" class="event-name" @click.prevent="selectEvent(event)">
          <span v-if="event.emoji">{{ event.emoji }}</span>
          {{ event.name }}
        </a> ({{eventDates(event)}})
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "src/colors";

.event-events {
  margin-top: 1rem;
}

.event-event {
  .event-name {
    color: $color-black;
  }
}

.event-event.selected {
  background-color: $color-event-selected;
}
</style>

<script>
import { DateTime } from "luxon";

export default {
  props: {
    events: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedEvent: {
      type: Object,
      default() {
        return null;
      },
    },
  },

  methods: {
    eventDates(event) {
      const start = DateTime.fromISO(event.utcStartDateTime, { zone: "UTC" });
      const startYear = start.toFormat("yyyy-MM");
      return startYear;
    },
    isEventSelected(event) {
      if (!this.selectedEvent || !this.selectedEvent.id) {
        return false;
      }
      return (this.selectedEvent.id === event.id);
    },
    selectEvent(event) {
      this.$emit("event-selected", event);
    },
  },

};
</script>
