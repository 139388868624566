<template>
  <div class="heartrate-chart">
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    records: { type: Array, default() { return []; } },
    height: { type: String, default: "50" },
    width: { type: String, default: "200" },
    hour: { type: String, default: "00" },
  },

  computed: {
    startTime() {
      return this.createDateTime(`${this.hour}:00:00`);
    },
    endTime() {
      return this.createDateTime(`${this.hour}:59:59`);
    },
  },

  mounted() {
    if (this.records.length > 0) {
      this.draw();
    }
  },

  methods: {
    createDateTime(hhmmss) {
      return new Date(`2000-01-01T${hhmmss}`);
    },

    defineGradients(svg) {
      const gradient = svg.append("defs")
        .append("linearGradient")
        .attr("id", "shade")
        .attr("x1", 0)
        .attr("x2", 0)
        .attr("y1", 0)
        .attr("y2", 1);

      gradient.append("stop").attr("stop-color", "rgba(255, 255, 255, 0)").attr("offset", "0%");
      gradient.append("stop").attr("stop-color", "rgba(255, 255, 255, 0)").attr("offset", "25%");
      gradient.append("stop").attr("stop-color", "rgba(255, 255, 255, 0.6)").attr("offset", "75%");
      gradient.append("stop").attr("stop-color", "rgba(255, 255, 255, 0.9)").attr("offset", "100%");
    },

    draw() {
      for (const child of this.$el.children) {
        this.$el.removeChild(child);
      }

      const margin = { bottom: 2 };

      const svg = d3.create("svg")
        .attr("viewBox", [0, 0, this.width, this.height])
        .attr("width", this.width)
        .attr("height", this.height);

      this.defineGradients(svg);

      const x = d3.scaleTime().domain([this.startTime, this.endTime]).range([0, this.width]);
      const y = d3.scaleLinear().domain([25, 175]).range([0, this.height - margin.bottom]);
      const bandwidth = Math.floor(this.width / 60 - 0.5);

      this.records.forEach((o) => {
        const date = this.createDateTime(o.utcStartTime);
        const xOffset = x(date);
        const yOffset = y(o.heartrate);
        svg.append("g")
          .attr("transform", `translate(${xOffset} ${this.height - yOffset - margin.bottom})`)
          .append("rect")
          .attr("fill", "black")
          .attr("width", bandwidth)
          .attr("height", yOffset);
      });

      svg.append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", this.width)
        .attr("height", this.height - 1)
        .attr("fill", "url(#shade)")
        .attr("style", "mix-blend-mode: screen;");

      this.$el.appendChild(svg.node());
    },
  },
};
</script>
