<template>
  <div class="dashboard">
    <header>
      <h1>data-house</h1>
      <div v-if="showLimitedDates" class="demo">
        Demo only shows data up to 2017.
      </div>
      <section>
        <h3>User</h3>
        <sign-in-dialog
          @sign-in-completed="userDidSignIn"
          @sign-in-finished="userSignInDidFail"
        />
      </section>
      <site-nav></site-nav>
    </header>
    <main>
      <section class="years">
        <div class="day-number">Y</div>
        <a
          v-for="year in years"
          :key="year"
          href="#"
          :class="{ selected: selectedYear == year }"
          @click.prevent="selectYear(year)"
          >{{ year }}</a
        >
      </section>
      <section class="months">
        <div class="day-number">M</div>
        <a
          v-for="month in months"
          :key="month"
          href="#"
          :class="{ selected: selectedMonth == month }"
          @click.prevent="selectMonth(month)"
          >{{ month }}</a
        >
      </section>

      <section class="days">
        <div class="timezones">
          <div><a href="#" @click.prevent="setTimezone('UTC')">UTC</a></div>
          <div><a href="#" @click.prevent="setTimezone('tokyo')">tokyo</a></div>
          <div>
            <a href="#" @click.prevent="setTimezone('london')">london</a>
          </div>
          <div>
            <a href="#" @click.prevent="setTimezone('newyork')">new york</a>
          </div>
          <div>
            <a href="#" @click.prevent="setTimezone('sanfrancisco')"
              >san francisco</a
            >
          </div>
        </div>
        <day-hour-matrix
          :year="selectedYear"
          :month="selectedMonth"
          :day="selectedDay"
          :hour="selectedHour"
          :days-matrix="daysMatrix"
          :hours="hours"
          :timezone="viewTimezone"
          @day-selected="selectDay"
          @day-hour-selected="selectDayHour"
        />
      </section>

      <section class="detail">
        <div v-if="editable" class="controls">
          <event-controls
            :selected-utc="selectedUtc"
            :selected-event="selectedEvent"
            @event-unselect="eventShouldUnselect"
          />
        </div>
        <hour-detail
          v-show="showHour"
          :hour-utc="selectedHourUtc"
          :hour-base-url="selectedHourUrl"
          :hour-files="selectedHourRecordFiles"
          :hour-path="selectedHourPath"
          :hour-tags="selectedHourTags"
          :editable="editable"
          @add-tag="addTag"
        />
        <day-summary
          v-show="showDay"
          :day-utc="selectedDayUtc"
          @day-selected="selectDay"
        />
        <event-list
          v-show="!showHour && !showDay"
          :events="visibleEvents"
          :selected-event="selectedEvent"
          @event-selected="eventDidSelect"
        />
      </section>
    </main>
  </div>
</template>

<style lang="scss" scoped>
@import "src/layout";
@import "src/colors";

.dashboard {
  display: flex;
  flex-direction: row;
}

.demo {
  color: rgb(128, 0, 0);
  padding: 2rem 0;
}

.selected {
  background-color: $color-very-light-grey;
}

.sidebar-section {
  padding: 1rem 0;
}

main {
  section {
    padding: 5rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
  }

  section.months {
    padding: 5rem 1rem 1rem 0.5rem;
  }

  section.days {
    padding: 2.0rem 0 1rem 0.5rem;
  }

  section.detail {
    padding: 5rem 1rem 1rem 2rem;
    flex-grow: 1;
  }
}

.timezones {
  display: flex;
  flex-direction: row;
  margin-left: 3rem;

  div {
    margin-left: 0.5rem;
  }
}

.controls {
  padding-bottom: 1rem;
  border-bottom: 1px solid $color-very-light-grey;
  margin-bottom: 1rem;
}
</style>

<script>
import flatten from "lodash/flatten";
import orderBy from "lodash/orderBy";
import countBy from "lodash/countBy";
import head from "lodash/head";
import toPairs from "lodash/toPairs";
import max from "lodash/max";
import { DateTime } from "luxon";
import firebase from "firebase/app";

import {
  yearNumber,
  monthNumber,
  dayNumber,
  hourNumber,
  yearString,
  monthString,
  dayString,
  hourString,
} from "../../functions/shared/units";
import flagEmojis from "../flag-emojis-by-code.json";
import { storage, db } from "../firebase";
import HourDetail from "../components/HourDetail.vue";
import DayHourMatrix from "../components/DayHourMatrix.vue";
import SignInDialog from "../components/SignInDialog.vue";
import EventControls from "../components/EventControls.vue";
import EventList from "../components/EventList.vue";
import DaySummary from "../components/DaySummary.vue";
import SiteNav from "../components/SiteNav.vue";

const LIMIT_DEMO_MAX_YEAR = 2021;

export default {
  components: {
    DaySummary,
    DayHourMatrix,
    EventControls,
    EventList,
    HourDetail,
    SignInDialog,
    SiteNav,
  },
  props: {
    demo: { type: Boolean, default: false },
  },
  data() {
    return {
      baseDataUrl: "http://localhost:8401",
      index: {},
      user: null,

      // Local timezone.
      selectedYear: LIMIT_DEMO_MAX_YEAR.toString(),
      selectedMonth: "12",
      selectedDay: "",
      selectedHour: "",

      viewTimezone: "Asia/Tokyo",

      hours: [],
      daysMatrix: [],

      tags: [],

      locationsByDateHour: {},
      firestoreSummary: [],

      events: [],
      selectedEvent: null,
    };
  },

  computed: {
    editable() {
      return !!this.user;
    },
    showLimitedDates() {
      return !this.user;
    },
    showDay() {
      return this.selectedDay !== "" && this.selectedHour === "";
    },
    showHour() {
      return this.selectedDay !== "" && this.selectedHour !== "";
    },
    years() {
      return orderBy(Object.keys(this.index).map((year) => yearNumber(year)));
    },
    months() {
      if (!this.index[this.selectedYear]) {
        return [];
      }
      return orderBy(
        Object.keys(this.index[this.selectedYear]).map((month) => monthNumber(month)),
      ).map((v) => monthString(v));
    },
    selectedUtc() {
      // Converting from this.selected{Year,Month,Day,Hour} to a UTC time.
      return DateTime.fromObject({
        year: yearNumber(this.selectedYear),
        month: monthNumber(this.selectedMonth),
        day: this.selectedDay ? dayNumber(this.selectedDay) : 1,
        hour: this.selectedHour ? dayNumber(this.selectedHour) : 0,
        zone: this.viewTimezone,
      }).toUTC();
    },
    selectedDayUtc() {
      // Converting from this.selected{Year,Month,Day,Hour} to a UTC time.
      return DateTime.fromObject({
        year: yearNumber(this.selectedYear),
        month: monthNumber(this.selectedMonth),
        day: this.selectedDay ? dayNumber(this.selectedDay) : 1,
        hour: 0,
        zone: this.viewTimezone,
      }).toUTC();
    },
    selectedHourUtc() {
      if (this.selectedHour === "") {
        return "00";
      }
      return hourString(this.selectedUtc.hour);
    },
    selectedHourUrl() {
      if (this.selectedHour !== "" && this.selectedDay !== "") {
        return `${this.baseDataUrl}/${this.selectedHourPath}`;
      }
      return "";
    },
    selectedHourPath() {
      if (this.selectedHour !== "" && this.selectedDay !== "") {
        // Need to convert from local timezone to UTC for the data.
        return this.selectedUtc.toFormat("yyyy/MM/dd/HH");
      }
      return "";
    },
    selectedHourRecordFiles() {
      if (this.selectedHour !== "" && this.selectedDay !== "") {
        const utc = this.selectedUtc;
        const utcYear = utc.toFormat("yyyy");
        const utcMonth = utc.toFormat("MM");
        const utcDay = utc.toFormat("dd");
        const utcHour = utc.toFormat("HH");

        if (
          this.index[utcYear]
          && this.index[utcYear][utcMonth]
          && this.index[utcYear][utcMonth][utcDay]
          && this.index[utcYear][utcMonth][utcDay][utcHour]
        ) {
          return this.index[utcYear][utcMonth][utcDay][utcHour].sources;
        }
      }
      return [];
    },
    selectedHourTags() {
      const utc = this.selectedUtc;
      return this.tagEmojiForHour(utc);
    },
    visibleEvents() {
      let { events } = this;
      events = this.events.filter((e) => {
        const start = DateTime.fromISO(e.utcStartDateTime, { zone: "utc" });
        return start.year === parseInt(this.selectedYear, 10);
      });
      if (this.showLimitedDates) {
        return events.filter((e) => {
          const start = DateTime.fromISO(e.utcStartDateTime, { zone: "utc" });
          return start.year <= LIMIT_DEMO_MAX_YEAR;
        });
      }
      return events;
    },
  },

  watch: {
    selectedMonth() {
      this.generateHoursMatrix();
    },
    selectedYear() {
      this.generateHoursMatrix();
    },
    tags() {
      this.generateHoursMatrix();
    },
    events() {
      this.generateHoursMatrix();
    },
    firestoreSummary() {
      const index = {};
      for (const monthlyIndex of this.firestoreSummary) {
        const [year, month] = monthlyIndex.id.split("-");
        index[year] = index[year] ? index[year] : {};
        index[year][month] = monthlyIndex.summary;
      }
      this.index = index;
      this.generateHoursMatrix();
    },
  },

  mounted() {
    if (this.$route.query.year) {
      this.selectedYear = this.$route.query.year;
    }
    if (this.$route.query.month) {
      this.selectedMonth = this.$route.query.month;
    }

    this.generateHours();
    this.refreshData();
  },

  methods: {
    refreshData() {
      let summaryRef = db.collection("summary");
      if (this.showLimitedDates) {
        summaryRef = summaryRef.where(
          "utcYear",
          "<=",
          LIMIT_DEMO_MAX_YEAR.toString(),
        );
      }
      this.$bind("firestoreSummary", summaryRef);
      this.getLocations();

      this.$bind("tags", db.collection("tags"));
      this.$bind(
        "events",
        db.collection("events").orderBy("utcStartDateTime", "asc"),
      );
    },
    getLocations() {
      storage
        .ref("locations.json")
        .getDownloadURL()
        .then((url) => fetch(url))
        .then((response) => response.json())
        .then((response) => {
          this.locationsByDateHour = response;
        });
    },
    utcDateTime(year, month, day, hour) {
      return DateTime.utc(
        yearNumber(year),
        monthNumber(month),
        dayNumber(day),
        hourNumber(hour),
      );
    },
    locationForDay(year, month, day) {
      if (!this.locationsByDateHour) {
        return {};
      }

      let locations = [];
      for (let h = 0; h < 24; h += 1) {
        const dateTime = this.utcDateTime(
          yearNumber(year),
          monthNumber(month),
          dayNumber(day),
          h,
        );
        const dateString = dateTime.toISO({
          suppressSeconds: true,
          suppressMilliseconds: true,
        });
        const locationsForHour = this.locationsByDateHour[dateString];
        if (locationsForHour) {
          locations = locations.concat(locationsForHour);
        }
      }

      if (locations.length < 1) {
        return {};
      }

      // Only one countryCode
      const countryCodes = countBy(
        locations.map((location) => location.countryCode),
      );
      if (Object.keys(countryCodes) === 1) {
        let cc = head(Object.keys(countryCodes));
        cc = flagEmojis[cc] ? flagEmojis[cc].emoji : cc;
        return {
          countryCode: cc,
        };
      }

      // Multiple country codes, we need to pick one.
      const countryCodesByFreq = orderBy(toPairs(countryCodes), (o) => o[1], [
        "desc",
      ]);
      let cc = head(countryCodesByFreq[0]);
      cc = flagEmojis[cc] ? flagEmojis[cc].emoji : cc;
      return {
        countryCode: cc,
      };
    },

    generateHours() {
      const year = yearNumber(this.selectedYear);
      const month = monthNumber(this.selectedMonth);
      const day = 1;
      const hours = Array.from(Array(24).keys())
        .map((v, i) => hourString(i))
        .map((h) => {
          const tokyoHour = DateTime.utc(
            year,
            month,
            day,
            hourNumber(h),
          ).setZone("Asia/Tokyo").hour;
          const newyorkHour = DateTime.utc(
            year,
            month,
            day,
            hourNumber(h),
          ).setZone("America/New_York").hour;
          const londonHour = DateTime.utc(
            year,
            month,
            day,
            hourNumber(h),
          ).setZone("Europe/London").hour;
          return {
            hour: h,
            local: hourString(h),
            timezones: `Tokyo: ${tokyoHour}:00\nNew York: ${newyorkHour}:00\nLondon: ${londonHour}:00`,
          };
        });
      this.hours = hours;
    },
    datasetExistence(datasets) {
      if (!datasets || datasets.length < 1) {
        return {};
      }

      return {
        hasSleep: datasets.filter((d) => d.match("sleep")).length > 0,
        hasCheckin: datasets.filter((d) => d.match("checkin")).length > 0,
        hasSteps: datasets.filter((d) => d.match("steps")).length > 0,
        hasFitbitSteps:
          datasets.filter((d) => d.match("fitbit-steps")).length > 0,
        hasJawboneSleep:
          datasets.filter((d) => d.match("jawbone-sleep")).length > 0,
        hasJawboneSteps:
          datasets.filter((d) => d.match("jawbone-steps")).length > 0,
        hasAppleSleep:
          datasets.filter((d) => d.match("applehealth-sleep")).length > 0,
        hasAppleSteps:
          datasets.filter((d) => d.match("applehealth-activity")).length > 0,
        hasArcTimeline:
          datasets.filter((d) => d.match("arc-timeline")).length > 0,
        hasSilentLog: datasets.filter((d) => d.match("silentlog")).length > 0,
        hasSnowTracks:
          datasets.filter(
            (d) => d.match("mytracks") || d.match("tracesnow") || d.match("slopes"),
          ).length > 0,
        hasTimeline:
          datasets.filter((d) => d.match("google-timeline")).length > 0,
        hasMoves: datasets.filter((d) => d.match("moves")).length > 0,
      };
    },
    datasetsString(datasets) {
      if (!datasets || datasets.length < 1) {
        return "";
      }

      return datasets.join("\n");
    },
    hasEnoughSteps(hourSummary) {
      if (
        !hourSummary
        || !hourSummary.totalSteps
        || !hourSummary.totalSteps.length
      ) {
        return true;
      }
      const maxSteps = max(
        hourSummary.totalSteps.map((stepSum) => stepSum.steps),
      );
      return maxSteps > 500;
    },
    emojiForData(hourSummary) {
      if (!hourSummary || !hourSummary.sources || !hourSummary.sources.length) {
        return { emoji: "" };
      }
      const datasets = hourSummary.sources;
      const stats = this.datasetExistence(datasets);

      if (stats.hasCheckin) {
        return { emoji: "📍" };
      }
      if (stats.hasSnowTracks) {
        return { emoji: "🏔" };
      }
      if (stats.hasArcTimeline) {
        return { emoji: "♒️" };
      }
      if (stats.hasSilentLog) {
        return { emoji: "🌀" };
      }
      if (stats.hasSleep) {
        return { emoji: "💤" };
      }
      if (
        (stats.hasAppleSteps || stats.hasSteps)
        && this.hasEnoughSteps(hourSummary)
      ) {
        return { emoji: "👣", emojiRotate: true };
      }
      if (stats.hasMoves) {
        return { emoji: "🧤" };
      }
      if (stats.hasTimeline) {
        return { emoji: "📈" };
      }

      switch (datasets.length) {
        case 1:
          return { emoji: "⠂" };
        case 2:
          return { emoji: "⠒" };
        case 3:
          return { emoji: "⠦" };
        case 4:
          return { emoji: "⠶" };
        case 5:
          return { emoji: "⠷" };
        default:
          return { emoji: datasets.length.toString() };
      }
    },

    tagEmojiForHour(utcDateTime) {
      const tags = this.tagsForMonth(utcDateTime);
      const cellId = utcDateTime.toISO({
        suppressMilliseconds: true,
        suppressSeconds: true,
      });
      if (tags[cellId]) {
        return tags[cellId].tags;
      }
      return "";
    },

    eventsForHour(utcDateTime) {
      const allEvents = flatten(Object.values(this.events));
      const matches = allEvents.filter((e) => {
        const eventStart = DateTime.fromISO(e.utcStartDateTime, {
          zone: "UTC",
        });
        const eventEnd = DateTime.fromISO(e.utcEndDateTime, { zone: "UTC" });
        return eventStart <= utcDateTime && utcDateTime <= eventEnd;
      });
      return matches;
    },

    generateHoursMatrix() {
      // The year and month in local time.
      const y = this.selectedYear;
      const m = this.selectedMonth;

      const daysInMonth = DateTime.utc(yearNumber(y), monthNumber(m), 1, 0)
        .plus({ months: 1 })
        .minus({ hours: 1 }).day;

      const localStartTime = DateTime.fromObject({
        year: yearNumber(y),
        month: monthNumber(m),
        day: 1,
        hour: 0,
        zone: this.viewTimezone,
      });
      const utcStartTime = localStartTime.toUTC();

      const days = [];
      for (let d = 1; d <= daysInMonth; d += 1) {
        const utcDayStart = utcStartTime.plus({ days: d - 1 });
        const localDayStart = localStartTime.plus({ days: d - 1 });
        const hours = [];

        for (let h = 0; h < 24; h += 1) {
          const utcHour = utcDayStart.plus({ hour: h });
          const uy = yearString(utcHour.year);
          const um = monthString(utcHour.month);
          const ud = dayString(utcHour.day);
          const uh = dayString(utcHour.hour);

          const cellId = utcHour.toISO({
            suppressMilliseconds: true,
            suppressSeconds: true,
          });
          let cell = {
            hour: hourString(h),
            utcDateTime: utcHour,
            id: cellId,
            exists: {},
            sources: [],
          };
          if (
            this.index[uy]
            && this.index[uy][um]
            && this.index[uy][um][ud]
            && this.index[uy][um][ud][uh]
          ) {
            const hourData = this.index[uy][um][ud][uh];
            const emoji = this.emojiForData(hourData);
            const { sources } = hourData;
            const exists = this.datasetExistence(sources);
            const hoverTitle = this.datasetsString(sources);
            cell = Object.assign(cell, emoji, {
              exists,
              hoverTitle,
              sources,
            });
          }
          // Override emoji if there is one from the tags.
          const tagEmoji = this.tagEmojiForHour(utcHour);
          if (tagEmoji) {
            cell.emoji = tagEmoji;
            cell.emojiRotate = false;
          }
          // Fetch events
          const events = this.eventsForHour(utcHour);
          if (events && events.length > 0) {
            cell.events = events;
          }

          hours.push(cell);
        } // hour

        // TODO: location not corrected by timezone.
        const location = this.locationForDay(y, m, d);
        const isWeekend = localDayStart.weekday > 5;
        days.push({
          day: dayString(d),
          utcDayStart,
          isWeekend,
          location,
          hours,
        });
      } // day

      // Update the data model.
      this.daysMatrix = Object.freeze(days);
    },

    selectYear(year) {
      const toSelectYear = yearString(year);
      if (toSelectYear !== this.selectedYear) {
        this.selectedYear = yearString(year);
        this.$router.push({
          path: this.$route.path,
          query: { year: this.selectedYear, month: this.selectedMonth },
        });
      }
    },

    selectMonth(month) {
      const toSelectMonth = monthString(month);
      if (toSelectMonth !== this.selectedMonth) {
        this.selectedMonth = toSelectMonth;
        this.$router.push({
          path: this.$route.path,
          query: { year: this.selectedYear, month: this.selectedMonth },
        });
      }
    },

    selectDay(day) {
      const toSelectDay = dayString(day);
      console.log("day selected", day);
      if (toSelectDay !== this.selectedDay) {
        this.selectedDay = toSelectDay;
      }
    },

    selectDayHour(isoDateTime) {
      const localTime = DateTime.fromISO(isoDateTime).setZone(
        this.viewTimezone,
      );
      const selectedDay = dayString(localTime.day);
      const selectedHour = hourString(localTime.hour);

      if (
        selectedDay === this.selectedDay
        && selectedHour === this.selectedHour
      ) {
        // Unselect the day if clicked twice.
        this.selectedDay = "";
        this.selectedHour = "";
      } else {
        this.selectedDay = selectedDay;
        this.selectedHour = selectedHour;
      }
    },

    setTimezone(location) {
      switch (location) {
        case "tokyo":
          this.viewTimezone = "Asia/Tokyo";
          break;
        case "london":
          this.viewTimezone = "Europe/London";
          break;
        case "newyork":
          this.viewTimezone = "America/New_York";
          break;
        case "sanfrancisco":
          this.viewTimezone = "America/Los_Angeles";
          break;
        default:
          this.viewTimezone = "UTC";
      }
      this.generateHours();
      this.generateHoursMatrix();
    },

    tagsForMonth(isoDateTime) {
      const yearMonth = isoDateTime.toFormat("yyyy-MM");
      const matching = this.tags.filter((doc) => doc.id.startsWith(yearMonth));
      if (matching && matching.length > 0) {
        return matching[0];
      }
      return {};
    },

    addTag(tags) {
      if (!this.editable) {
        return;
      }
      const yearMonth = this.selectedUtc.toFormat("yyyy-MM");
      const isoDate = this.selectedUtc.toISO({
        suppressMilliseconds: true,
        suppressSeconds: true,
      });
      const update = {};
      if (tags) {
        update[isoDate] = { tags };
      } else {
        update[isoDate] = firebase.firestore.FieldValue.delete();
      }

      const doc = db.collection("tags").doc(yearMonth);
      doc
        .update(update)
        .catch(() => {
          doc.set(update);
        })
        .then(() => {
          console.log(`Updated ${yearMonth} with ${isoDate}: ${tags}`);
        });
    },

    userDidSignIn(user) {
      this.user = user;
      this.refreshData();
    },

    userSignInDidFail() {
      this.user = null;
      this.refreshData();
    },

    eventDidSelect(event) {
      if (this.selectedEvent && this.selectedEvent.id === event.id) {
        this.selectedEvent = null;
      } else {
        this.selectedEvent = event;
        this.showEvent(event);
      }
    },

    eventShouldUnselect() {
      this.selectedEvent = null;
    },

    showEvent(event) {
      const start = DateTime.fromISO(event.utcStartDateTime, { zone: "UTC" });
      this.selectedYear = yearString(start.year);
      this.selectedMonth = monthString(start.month);
      this.selectMonth(this.selectedMonth);
    },
  },
};
</script>
