import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

import credentials from "../credentials-firebase.json";

export const app = firebase.initializeApp(credentials);
export const storage = app.storage();
export const db = app.firestore();
