<template>
  <div class="event-controls">
    <h3>Event Add/Edit</h3>
    <input v-model="eventName" type="text" placeholder="Name" />
    <input v-model="eventEmoji" type="text" placeholder="Emoji"/>
    <div>
      <a href="#" @click.prevent="markEventStart">Mark Event Start</a>
      <span v-if="eventStartUtc">
        {{ eventStartUtc.toFormat("yyyy-MM-dd HH:mm") }}
      </span>
    </div>
    <div>
      <a href="#" @click.prevent="markEventEnd">Mark Event End</a>
      <span v-if="eventEndUtc">
        {{ eventEndUtc.toFormat("yyyy-MM-dd HH:mm") }}
      </span>
    </div>
    <div class="button-row">
      <button class="delete" @click.prevent="eventDelete">Delete</button>
      <button @click.prevent="eventUnselect">Clear</button>
      <button @click.prevent="eventAdd">{{buttonLabel}}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.button-row {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin: 0 0.5rem;
    min-width: 5rem;
    cursor: pointer;
  }

  .delete {
    background-color: rgb(255, 199, 199);
  }
}
.event-controls {
  display: flex;
  flex-direction: column;
}
</style>

<script>
import { DateTime } from "luxon";
import { db } from "../firebase";
import { isoString } from "../../functions/shared/units";

export default {
  props: {
    selectedUtc: {
      type: Object,
      default() {
        return null;
      },
    },
    selectedEvent: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      eventStartUtc: null,
      eventEndUtc: null,
      eventName: "",
      eventEmoji: "",
    };
  },

  computed: {
    buttonLabel() {
      if (this.selectedEvent) {
        return "Update";
      }
      return "Add";
    },
  },

  watch: {
    selectedEvent(newSelectedEvent) {
      if (newSelectedEvent) {
        this.eventName = newSelectedEvent.name;
        this.eventEmoji = newSelectedEvent.emoji;
        if (newSelectedEvent.utcStartDateTime) {
          this.eventStartUtc = DateTime.fromISO(newSelectedEvent.utcStartDateTime, { zone: "UTC" });
        } else {
          this.eventStartUtc = null;
        }

        if (newSelectedEvent.utcEndDateTime) {
          this.eventEndUtc = DateTime.fromISO(newSelectedEvent.utcEndDateTime, { zone: "UTC" });
        } else {
          this.eventEndUtc = null;
        }
      } else {
        this.reset();
      }
    },
  },

  methods: {

    reset() {
      this.eventName = "";
      this.eventEmoji = "";
      this.eventStartUtc = null;
      this.eventEndUtc = null;
    },
    markEventStart() {
      // Take the selected event time and make it the start of the event.
      this.eventStartUtc = this.selectedUtc;
    },

    markEventEnd() {
      // Take the selected event time and make it the start of the event.
      this.eventEndUtc = this.selectedUtc;
    },

    eventAdd() {
      if (this.eventName && this.eventStartUtc && this.eventEndUtc) {
        const startTimeString = isoString(this.eventStartUtc);
        const endTimeString = isoString(this.eventEndUtc);
        const docName = `${startTimeString} ${endTimeString}`;

        const eventDoc = {
          name: this.eventName,
          emoji: this.eventEmoji ? this.eventEmoji : "",
          utcStartDateTime: startTimeString,
          utcEndDateTime: endTimeString,
        };

        db.collection("events")
          .doc(docName)
          .set(eventDoc)
          .then(() => {
            console.log("Done");
            this.reset();
          });
      }
    },
    eventDelete() {
      if (this.eventName && this.eventStartUtc && this.eventEndUtc) {
        const startTimeString = isoString(this.eventStartUtc);
        const endTimeString = isoString(this.eventEndUtc);
        const docName = `${startTimeString} ${endTimeString}`;
        // TODO: confirm delete
        db.collection("events")
          .doc(docName)
          .delete();
        this.eventUnselect();
      }
    },
    eventUnselect() {
      this.$emit("event-unselect");
    },
  },
};
</script>
