<template>
  <div class="hour-detail">
    <div class="notes">
      <div v-if="editable" class="input">
        <div>
          <input
            v-model="tags"
            type="text"
            name="tags"
            placeholder="Tags"
            @blur="tagsInputDidBlur"
          />
        </div>
      </div>
    </div>
    <div v-for="record in records" :key="record.id" class="hour-record-group">
      <h2>{{ sourceEmoji[record.id] }} {{ record.id }}</h2>
      <div v-if="record.id.match('applehealth-activity')">
        <steps-chart
          :hour="hourUtc"
          :records="record.data"
          format="applehealth"
          value-key="value"
        />
      </div>
      <div v-if="record.id.match('applehealth-sleep')">
        <sleep-chart :hour="hourUtc" :records="record.rows" />
      </div>
      <div v-if="record.id.match('fitbit-steps')">
        <steps-chart :hour="hourUtc" :records="record.data" />
      </div>
      <div v-if="record.id.match('fitbit-heartrate')">
        <heart-rate-chart :hour="hourUtc" :records="record.data" />
      </div>
      <div v-if="record.id.match('fitbit-sleep')">
        <sleep-chart :hour="hourUtc" :records="record.rows" />
      </div>
      <div v-if="record.id.match('jawbone-heartrate')">
        <heart-rate-chart :hour="hourUtc" :records="record.data" />
      </div>
      <div v-if="record.id.match('jawbone-steps')">
        <steps-chart :hour="hourUtc" :records="record.data" />
      </div>
      <div v-if="record.id.match('google-timeline')">
        <static-map :records="record.data" source-type="google" />
      </div>
      <div v-if="record.id.match('arc-timeline')">
        <static-map :records="record.rows" source-type="arc" />
      </div>
      <div v-if="record.id.match('moves-storyline')">
        <static-map :records="record.rows" source-type="moves" />
      </div>
      <div v-if="record.id.match('google-location-history')">
        <static-map :records="record.rows" source-type="arc" />
      </div>
      <div v-if="record.id.match('(mytracks|tracesnow|slopes|silentlog)')">
        <static-map :records="record.rows" source-type="gpx" />
      </div>
      <div v-if="record.id.match('foursquare-checkins')">
        <static-map :records="record.rows" source-type="foursquare" />
      </div>
      <div v-for="row in record.rows" :key="row.id" class="record-row">
        <h3><a :href="row.url" target="_blank">{{ row.name }}</a></h3>
        <div class="subtitle">{{ row.subtitle }}</div>
        <div class="time">
          utc: {{ row.utcStartTime }} (local: {{ row.localStartTime }})
        </div>

        <div class="raw hidden" @click="toggleRaw">
          <div class="raw-toggle">Raw Data</div>
          {{ row.raw }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "src/colors";

.hidden {
  max-height: 1rem;
  overflow-y: hidden;
}

.notes .input {
  font-size: 0.8rem;

  input {
    width: 100%;
  }
}
</style>

<script>
import orderBy from "lodash/orderBy";
import { summarizeRecords } from "../../functions/shared/record-summary";
import StepsChart from "./StepsChart.vue";
import HeartRateChart from "./HeartRateChart.vue";
import SleepChart from "./SleepChart.vue";
import StaticMap from "./StaticMap.vue";

import { db } from "../firebase";

export default {
  components: {
    StaticMap,
    StepsChart,
    SleepChart,
    HeartRateChart,
  },
  props: {
    hourUtc: { type: String, default: "00" },
    hourBaseUrl: { type: String, default: "" },
    hourPath: { type: String, default: "" },
    hourFiles: {
      type: Array,
      default() {
        return [];
      },
    },
    hourTags: { type: String, default: "" },
    editable: { type: Boolean, default: false },
  },

  data() {
    return {
      fileRecords: [],
      dbRecords: {},
      localSources: [
        // "google-location-history",
      ],
      sourceOrder: [
        "foursquare-checkins",
        "moves-storyline",
        "arc-timeline",
        "silentlog",
        "mytracks",
        "tracesnow",
        "slopes",
        "google-location-history",
        "google-timeline",
        "jawbone-steps",
        "fitbit-steps",
        "applehealth-activity",
        "jawbone-heartrate",
        "fitbit-heartrate",
        "jawbone-sleep",
        "fitbit-sleep",
        "applehealth-sleep",
      ],
      sourceEmoji: {
        "foursquare-checkins": "📍",
        "moves-storyline": "📍",
        "google-timeline": "📍",
        "arc-timeline": "♒️",
        silentlog: "🌀",
        mytracks: "🏔",
        tracesnow: "🏔",
        slopes: "🏔",
        "jawbone-steps": "🦶",
        "fitbit-steps": "🦶",
        "applehealth-activity": "🦶",
        "jawbone-heartrate": "🖤",
        "fitbit-heartrate": "🖤",
        "jawbone-sleep": "💤",
        "fitbit-sleep": "💤",
        "applehealth-sleep": "💤",
      },
      tags: "",
    };
  },

  computed: {
    records() {
      let records = [];
      if (!this.dbRecords) {
        return records;
      }
      for (const key of Object.keys(this.dbRecords)) {
        if (key.startsWith("utc")) {
          // eslint-disable-next-line no-continue
          continue;
        }
        if (key === "jawbone-sleep.json") {
          // ignore this duplicated data
          // eslint-disable-next-line no-continue
          continue;
        }
        records.push({
          id: key,
          data: this.dbRecords[key],
          rows: summarizeRecords(key, this.dbRecords[key], true),
        });
      }

      // Merge also any fileRecords if they exist.
      if (this.fileRecords) {
        records = records.concat(this.fileRecords);
      }
      // Sort records by a hardcoded order
      records = orderBy(
        records,
        [(o) => this.sourceOrder.indexOf(o.id)],
        ["asc"],
      );

      return records;
    },
  },

  watch: {
    hourPath(newPath) {
      if (newPath && newPath.length > 0) {
        const parts = newPath.split("/");
        const ref = db
          .collection("timeline")
          .doc(`${parts[0]}-${parts[1]}-${parts[2]}T${parts[3]}:00:00Z`);
        this.$bind("dbRecords", ref);
        this.fetchHourRecords();
      }
    },
    hourTags(newValue) {
      this.tags = newValue;
    },
  },

  methods: {
    fetchHourRecords() {
      const requests = this.localSources.map((sourceName) => {
        const url = `${this.hourBaseUrl}/${sourceName}.json`;
        return fetch(url)
          .then((response) => response.json())
          .then((response) => ({
            id: sourceName,
            url,
            data: response,
            rows: summarizeRecords(sourceName, response, true),
          }));
      });

      Promise.all(requests).then((responses) => {
        this.fileRecords = responses;
      });
    },

    toggleRaw(e) {
      let { target } = e;
      while (!target.classList.contains("raw")) {
        target = target.parentElement;
      }
      target.classList.toggle("hidden");
    },

    tagsInputDidBlur() {
      this.$emit("add-tag", this.tags);
    },
  },
};
</script>
