<template>
  <div class="home">
    <header>
      <h1>data-house</h1>
      <section>
        <h3>User</h3>
        <sign-in-dialog
          @sign-in-completed="userDidSignIn"
          @sign-in-finished="userSignInDidFail"
        />
      </section>
    </header>
    <main>
      <section>
        <h1>views</h1>
        <div>
          <div><router-link to="/dash">Dashboard</router-link></div>
          <div><router-link to="/trips">Trips</router-link></div>
          <div><router-link to="/places">Places</router-link></div>
        </div>
      </section>
    </main>
  </div>
</template>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: row;
}
</style>

<script>
import SignInDialog from "../components/SignInDialog.vue";

export default {
  components: {
    SignInDialog,
  },

  data() {
    return {
      user: null,
    };
  },

  methods: {
    userDidSignIn(user) {
      this.user = user;
    },

    userSignInDidFail() {
      this.user = null;
    },

  },
};
</script>
