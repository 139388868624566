<template>
  <div class="day-summary">
    <h3>Day</h3>
    <div v-for="source in sources" :key="source">
      <h4>{{source}}</h4>
      <div v-for="record in records[source]" :key="record.id">
        {{record.name}}
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { summarizeDay } from "../../functions/shared/day-summary";
import { db } from "../firebase";

export default {
  props: {
    dayUtc: { type: Object, default() { return null; } },
  },

  data() {
    return {
      sources: [],
      records: {},
    };
  },

  mounted() {
    this.reloadRecords();
  },

  watch: {
    dayUtc() {
      this.reloadRecords();
    },
  },

  methods: {
    async reloadRecords() {
      if (this.dayUtc) {
        const records = await summarizeDay(firebase, db, this.dayUtc);
        console.log(records);
        this.sources = Object.keys(records);
        this.records = records;
      }
    },
  },
};
</script>
