<template>
  <div class="dashboard">
    <header>
      <h1>data-house</h1>
      <div v-if="showLimitedDates" class="demo">
        Demo only shows data up to 2017.
      </div>
      <section>
        <h3>User</h3>
        <sign-in-dialog
          @sign-in-completed="userDidSignIn"
          @sign-in-finished="userSignInDidFail"
        />
      </section>
      <site-nav></site-nav>
    </header>
    <main>
      <section class="years">
        <div class="day-number">Y</div>
        <a
          v-for="year in years"
          :key="year"
          href="#"
          :class="{ selected: selectedYear == year }"
          @click.prevent="selectYear(year)"
          >{{ year }}</a
        >
      </section>
      <section class="months">
        <div class="day-number">M</div>
        <a
          v-for="month in months"
          :key="month"
          href="#"
          :class="{ selected: selectedMonth == month }"
          @click.prevent="selectMonth(month)"
          >{{ month }}</a
        >
      </section>

      <section class="places">
        <div v-for="day in visitsByDays" :key="day.day">
          <div class="place-timestamp">{{day.day}}</div>
          <div v-for="visit in day.visits" :key="visit.utcStartDateTime" class="place">
            <div class="place-name">
              <a :href="visit.url" target="_blank">({{visit.type}}) {{visit.name}}</a>
              <span v-if="visit.mapUrl">
                &nbsp;(<a :href="visit.mapUrl" target="_blank">Map</a>)
              </span>
            </div>
          </div>
        </div>
      </section>

      <section class="detail">
        <div class="timezones">
          <div><a href="#" @click.prevent="setTimezone('UTC')">UTC</a></div>
          <div><a href="#" @click.prevent="setTimezone('tokyo')">tokyo</a></div>
          <div>
            <a href="#" @click.prevent="setTimezone('london')">london</a>
          </div>
          <div>
            <a href="#" @click.prevent="setTimezone('newyork')">new york</a>
          </div>
          <div>
            <a href="#" @click.prevent="setTimezone('sanfrancisco')"
              >san francisco</a
            >
          </div>
        </div>

        <hour-detail
          v-show="showHour"
          :hour-utc="selectedHourUtc"
          :hour-base-url="selectedHourUrl"
          :hour-files="selectedHourRecordFiles"
          :hour-path="selectedHourPath"
          :hour-tags="selectedHourTags"
          :editable="editable"
          @add-tag="addTag"
        />

      </section>
    </main>
  </div>
</template>

<style lang="scss" scoped>
@import "src/layout";
@import "src/colors";

.dashboard {
  display: flex;
  flex-direction: row;
}

.demo {
  color: rgb(128, 0, 0);
  padding: 2rem 0;
}

.selected {
  background-color: $color-very-light-grey;
}

.sidebar-section {
  padding: 1rem 0;
}

main {
  section {
    padding: 5rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
  }

  section.months {
    padding: 5rem 1rem 1rem 0.5rem;
  }

  section.days {
    padding: 2rem 0 1rem 0.5rem;
  }

  section.detail {
    padding: 5rem 1rem 1rem 2rem;
    flex-grow: 1;
  }
}

.timezones {
  display: flex;
  flex-direction: row;
  margin-left: 3rem;

  div {
    margin-left: 0.5rem;
  }
}

.controls {
  padding-bottom: 1rem;
  border-bottom: 1px solid $color-very-light-grey;
  margin-bottom: 1rem;
}

.place-timestamp {
  color: $color-black;
}

.place-name {
    color: $color-grey;
}
.place {
  margin: 0.5rem 0.5rem;
}
</style>

<script>
import orderBy from "lodash/orderBy";
import max from "lodash/max";
import flatten from "lodash/flatten";
import { DateTime } from "luxon";
import firebase from "firebase/app";

import {
  yearNumber,
  monthNumber,
  dayNumber,
  hourNumber,
  yearString,
  monthString,
  dayString,
  hourString,
} from "../../functions/shared/units";

// import { summarizeRecords } from "../../functions/shared/record-summary";
import { storage, db } from "../firebase";
import HourDetail from "../components/HourDetail.vue";
import SignInDialog from "../components/SignInDialog.vue";
import SiteNav from "../components/SiteNav.vue";

const LIMIT_DEMO_MAX_YEAR = 2021;

export default {
  components: {
    HourDetail,
    SignInDialog,
    SiteNav,
  },
  props: {
    demo: { type: Boolean, default: false },
  },
  data() {
    return {
      baseDataUrl: "http://localhost:8401",
      index: {},
      user: null,

      // Local timezone.
      selectedYear: LIMIT_DEMO_MAX_YEAR.toString(),
      selectedMonth: "12",
      selectedDay: "",
      selectedHour: "",

      viewTimezone: "Asia/Tokyo",

      hours: [],
      placeRecords: [],
      visitsByDays: [],

      tags: [],

      locationsByDateHour: {},
      firestoreSummary: [],

    };
  },

  computed: {
    editable() {
      return !!this.user;
    },
    showLimitedDates() {
      return !this.user;
    },
    showDay() {
      return this.selectedDay !== "" && this.selectedHour === "";
    },
    showHour() {
      return this.selectedDay !== "" && this.selectedHour !== "";
    },
    years() {
      return orderBy(Object.keys(this.index).map((year) => yearNumber(year)));
    },
    months() {
      if (!this.index[this.selectedYear]) {
        return [];
      }
      return orderBy(
        Object.keys(this.index[this.selectedYear]).map((month) => monthNumber(month)),
      ).map((v) => monthString(v));
    },
    selectedUtc() {
      // Converting from this.selected{Year,Month,Day,Hour} to a UTC time.
      return DateTime.fromObject({
        year: yearNumber(this.selectedYear),
        month: monthNumber(this.selectedMonth),
        day: this.selectedDay ? dayNumber(this.selectedDay) : 1,
        hour: this.selectedHour ? dayNumber(this.selectedHour) : 0,
        zone: this.viewTimezone,
      }).toUTC();
    },
    selectedDayUtc() {
      // Converting from this.selected{Year,Month,Day,Hour} to a UTC time.
      return DateTime.fromObject({
        year: yearNumber(this.selectedYear),
        month: monthNumber(this.selectedMonth),
        day: this.selectedDay ? dayNumber(this.selectedDay) : 1,
        hour: 0,
        zone: this.viewTimezone,
      }).toUTC();
    },
    selectedHourUtc() {
      if (this.selectedHour === "") {
        return "00";
      }
      return hourString(this.selectedUtc.hour);
    },
    selectedHourUrl() {
      if (this.selectedHour !== "" && this.selectedDay !== "") {
        return `${this.baseDataUrl}/${this.selectedHourPath}`;
      }
      return "";
    },
    selectedHourPath() {
      if (this.selectedHour !== "" && this.selectedDay !== "") {
        // Need to convert from local timezone to UTC for the data.
        return this.selectedUtc.toFormat("yyyy/MM/dd/HH");
      }
      return "";
    },
    selectedHourRecordFiles() {
      if (this.selectedHour !== "" && this.selectedDay !== "") {
        const utc = this.selectedUtc;
        const utcYear = utc.toFormat("yyyy");
        const utcMonth = utc.toFormat("MM");
        const utcDay = utc.toFormat("dd");
        const utcHour = utc.toFormat("HH");

        if (
          this.index[utcYear]
          && this.index[utcYear][utcMonth]
          && this.index[utcYear][utcMonth][utcDay]
          && this.index[utcYear][utcMonth][utcDay][utcHour]
        ) {
          return this.index[utcYear][utcMonth][utcDay][utcHour].sources;
        }
      }
      return [];
    },
    selectedHourTags() {
      const utc = this.selectedUtc;
      return this.tagEmojiForHour(utc);
    },
    visibleEvents() {
      let { events } = this;
      events = this.events.filter((e) => {
        const start = DateTime.fromISO(e.utcStartDateTime, { zone: "utc" });
        return start.year === parseInt(this.selectedYear, 10);
      });
      if (this.showLimitedDates) {
        return events.filter((e) => {
          const start = DateTime.fromISO(e.utcStartDateTime, { zone: "utc" });
          return start.year <= LIMIT_DEMO_MAX_YEAR;
        });
      }
      return events;
    },
  },

  watch: {
    selectedMonth() {
      this.generatePlaceList();
    },
    selectedYear() {
      this.generatePlaceList();
    },
    // tags() {
    //   this.generatePlaceList();
    // },
    firestoreSummary() {
      const index = {};
      for (const monthlyIndex of this.firestoreSummary) {
        const [year, month] = monthlyIndex.id.split("-");
        index[year] = index[year] ? index[year] : {};
        index[year][month] = monthlyIndex.summary;
      }
      this.index = index;
      this.generatePlaceList();
    },
  },

  mounted() {
    if (this.$route.query.year) {
      this.selectedYear = this.$route.query.year;
    }
    if (this.$route.query.month) {
      this.selectedMonth = this.$route.query.month;
    }

    this.generateHours();
    this.refreshData();
  },

  methods: {
    refreshData() {
      let summaryRef = db.collection("summary");
      if (this.showLimitedDates) {
        summaryRef = summaryRef.where(
          "utcYear",
          "<=",
          LIMIT_DEMO_MAX_YEAR.toString(),
        );
      }
      this.$bind("firestoreSummary", summaryRef);
      this.getLocations();

      this.$bind("tags", db.collection("tags"));
    },
    getLocations() {
      storage
        .ref("locations.json")
        .getDownloadURL()
        .then((url) => fetch(url))
        .then((response) => response.json())
        .then((response) => {
          this.locationsByDateHour = response;
        });
    },
    utcDateTime(year, month, day, hour) {
      return DateTime.utc(
        yearNumber(year),
        monthNumber(month),
        dayNumber(day),
        hourNumber(hour),
      );
    },
    generateHours() {
      const year = yearNumber(this.selectedYear);
      const month = monthNumber(this.selectedMonth);
      const day = 1;
      const hours = Array.from(Array(24).keys())
        .map((v, i) => hourString(i))
        .map((h) => {
          const tokyoHour = DateTime.utc(
            year,
            month,
            day,
            hourNumber(h),
          ).setZone("Asia/Tokyo").hour;
          const newyorkHour = DateTime.utc(
            year,
            month,
            day,
            hourNumber(h),
          ).setZone("America/New_York").hour;
          const londonHour = DateTime.utc(
            year,
            month,
            day,
            hourNumber(h),
          ).setZone("Europe/London").hour;
          return {
            hour: h,
            local: hourString(h),
            timezones: `Tokyo: ${tokyoHour}:00\nNew York: ${newyorkHour}:00\nLondon: ${londonHour}:00`,
          };
        });
      this.hours = hours;
    },

    datasetsString(datasets) {
      if (!datasets || datasets.length < 1) {
        return "";
      }

      return datasets.join("\n");
    },
    hasEnoughSteps(hourSummary) {
      if (
        !hourSummary
        || !hourSummary.totalSteps
        || !hourSummary.totalSteps.length
      ) {
        return true;
      }
      const maxSteps = max(
        hourSummary.totalSteps.map((stepSum) => stepSum.steps),
      );
      return maxSteps > 500;
    },

    hasPlaces(hourSummary) {
      if (!hourSummary || !hourSummary.sources || !hourSummary.sources.length) {
        return { emoji: "" };
      }
      const datasets = hourSummary.sources;
      const checkins = datasets.filter((d) => d.match("checkin"));
      return checkins && checkins.length > 0;
    },

    tagEmojiForHour(utcDateTime) {
      const tags = this.tagsForMonth(utcDateTime);
      const cellId = utcDateTime.toISO({
        suppressMilliseconds: true,
      });
      if (tags[cellId]) {
        return tags[cellId].tags;
      }
      return "";
    },

    async generatePlaceList() {
      // The year and month in local time.
      const y = this.selectedYear;
      const m = this.selectedMonth;

      const daysInMonth = DateTime.utc(yearNumber(y), monthNumber(m), 1, 0)
        .plus({ months: 1 })
        .minus({ hours: 1 }).day;

      const localStartTime = DateTime.fromObject({
        year: yearNumber(y),
        month: monthNumber(m),
        day: 1,
        hour: 0,
        zone: this.viewTimezone,
      });
      const utcStartTime = localStartTime.toUTC();

      const visitsByDay = [];
      for (let d = 1; d <= daysInMonth; d += 1) {
        const utcDayStart = utcStartTime.plus({ days: d - 1 });
        const localDayStart = localStartTime.plus({ days: d - 1 });
        const cellsWithVisits = [];

        for (let h = 0; h < 24; h += 1) {
          const utcHour = utcDayStart.plus({ hour: h });
          const uy = yearString(utcHour.year);
          const um = monthString(utcHour.month);
          const ud = dayString(utcHour.day);
          const uh = dayString(utcHour.hour);

          const cellId = utcHour.toISO({
            suppressMilliseconds: true,
          });

          if (
            this.index[uy]
            && this.index[uy][um]
            && this.index[uy][um][ud]
            && this.index[uy][um][ud][uh]
          ) {
            const hourData = this.index[uy][um][ud][uh];
            const hourHasVisits = this.hasPlaces(hourData);
            if (hourHasVisits) {
              cellsWithVisits.push(cellId);
            }
          }
        } // hour

        visitsByDay.push({
          cellsWithVisits,
          day: localDayStart.toFormat("yyyy-MM-dd"),
        });
      } // day

      const resolvedVisitsByDay = await Promise.all(visitsByDay.map(async ({ cellsWithVisits, day }) => {
        const visitRecords = await Promise.all(cellsWithVisits.map((cellId) => db
          .collection("timeline")
          .doc(cellId)
          .get().then((snapshot) => {
            if (snapshot.exists) {
              const records = snapshot.data();
              return this.visitsFromRecords(records);
            }
            return [];
          })));
        return {
          day,
          visits: orderBy(flatten(visitRecords), ["utcStartDateTime"], ["asc"]),
        };
      }));

      console.log("resolvedVisits", resolvedVisitsByDay);

      this.visitsByDays = resolvedVisitsByDay;
    },

    visitsFromRecords(records) {
      const timelineVisits = records["google-timeline"] ? records["google-timeline"].filter((record) => record.placeVisit) : [];
      const foursquareVisits = records["foursquare-checkins"];

      const visits = [];
      if (timelineVisits) {
        for (const visit of timelineVisits) {
          visits.push({
            utcStartDateTime: visit.utcStartDateTime,
            name: visit.placeVisit.location.name,
            url: this.placeVisitUrl(visit.placeVisit),
            type: "Goog",
            // record: visit,
          });
        }
      }
      if (foursquareVisits) {
        for (const checkin of foursquareVisits) {
          visits.push({
            utcStartDateTime: checkin.utcStartDateTime,
            name: checkin.venue.name,
            url: this.foursquareVenueUrl(checkin.venue),
            mapsUrl: this.mapsUrlForVenue(checkin.venue),
            type: "4sq",
            // record: checkin,
          });
        }
      }
      return Object.freeze(visits);
    },

    selectYear(year) {
      const toSelectYear = yearString(year);
      if (toSelectYear !== this.selectedYear) {
        this.selectedYear = yearString(year);
        this.$router.push({
          path: this.$route.path,
          query: { year: this.selectedYear, month: this.selectedMonth },
        });
      }
    },

    selectMonth(month) {
      const toSelectMonth = monthString(month);
      if (toSelectMonth !== this.selectedMonth) {
        this.selectedMonth = toSelectMonth;
        this.$router.push({
          path: this.$route.path,
          query: { year: this.selectedYear, month: this.selectedMonth },
        });
      }
    },

    selectDay(day) {
      const toSelectDay = dayString(day);
      console.log("day selected", day);
      if (toSelectDay !== this.selectedDay) {
        this.selectedDay = toSelectDay;
      }
    },

    selectDayHour(isoDateTime) {
      const localTime = DateTime.fromISO(isoDateTime).setZone(
        this.viewTimezone,
      );
      const selectedDay = dayString(localTime.day);
      const selectedHour = hourString(localTime.hour);

      if (
        selectedDay === this.selectedDay
        && selectedHour === this.selectedHour
      ) {
        // Unselect the day if clicked twice.
        this.selectedDay = "";
        this.selectedHour = "";
      } else {
        this.selectedDay = selectedDay;
        this.selectedHour = selectedHour;
      }
    },

    setTimezone(location) {
      switch (location) {
        case "tokyo":
          this.viewTimezone = "Asia/Tokyo";
          break;
        case "london":
          this.viewTimezone = "Europe/London";
          break;
        case "newyork":
          this.viewTimezone = "America/New_York";
          break;
        case "sanfrancisco":
          this.viewTimezone = "America/Los_Angeles";
          break;
        default:
          this.viewTimezone = "UTC";
      }
      this.generateHours();
      this.generatePlaceList();
    },

    tagsForMonth(isoDateTime) {
      const yearMonth = isoDateTime.toFormat("yyyy-MM");
      const matching = this.tags.filter((doc) => doc.id.startsWith(yearMonth));
      if (matching && matching.length > 0) {
        return matching[0];
      }
      return {};
    },

    addTag(tags) {
      if (!this.editable) {
        return;
      }
      const yearMonth = this.selectedUtc.toFormat("yyyy-MM");
      const isoDate = this.selectedUtc.toISO({
        suppressMilliseconds: true,
        suppressSeconds: true,
      });
      const update = {};
      if (tags) {
        update[isoDate] = { tags };
      } else {
        update[isoDate] = firebase.firestore.FieldValue.delete();
      }

      const doc = db.collection("tags").doc(yearMonth);
      doc
        .update(update)
        .catch(() => {
          doc.set(update);
        })
        .then(() => {
          console.log(`Updated ${yearMonth} with ${isoDate}: ${tags}`);
        });
    },

    userDidSignIn(user) {
      this.user = user;
      this.refreshData();
    },

    userSignInDidFail() {
      this.user = null;
      this.refreshData();
    },

    placeVisitUrl(visit) {
      const encodedAddress = encodeURIComponent(visit.location.address);
      const { placeId } = visit.location;
      return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}&query_place_id=${placeId}`;
    },

    foursquareVenueUrl(venue) {
      return `https://foursquare.com/v/${venue.id}`;
    },

    mapsUrlForVenue(venue) {
      return `https://www.google.com/maps/?q=${venue.location.lat},${venue.location.lng}`;
    },
  },
};
</script>
