/* eslint-disable import/prefer-default-export */
import { DateTime, Duration } from "luxon";
import countBy from "lodash/countBy";

const parseFoursquareCheckins = (response, includeRaw) => {
  const checkins = [];
  response.forEach((record) => {
    checkins.push({
      id: record.id,
      name: record.venue.name,
      subtitle: record.venue.location.country,
      url: `https://foursquare.com/v/${record.venue.id}`,
      utcStartTime: record.utcStartTime,
      utcStartIso: record.utcStartDateTime,
      localStartTime: DateTime.fromISO(record.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
      raw: includeRaw ? record : null,
    });
  });
  return checkins;
};

const parseArcTimeline = (records, includeRaw) => {
  const firstReading = records[0];
  if (!firstReading) {
    return [];
  }

  const activeRecords = records.filter((record) => record.activity !== "stationary" && record.speed !== -1);
  const activityTitle = `arc ${records.length} records (${activeRecords.length} active).`;

  return [{
    id: `arc:${firstReading.utcStartDateTime}`,
    name: activityTitle,
    points: activeRecords,
    utcStartTime: firstReading.utcStartTime,
    localStartTime: DateTime.fromISO(firstReading.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
    raw: includeRaw ? records : null,
  }];
};

const parseGoogleLocationHistory = (records, includeRaw) => {
  const firstReading = records[0];
  if (!firstReading) {
    return [];
  }

  const categories = countBy(records, (r) => r.deviceTag);
  const stats = Object.keys(categories).map((k) => `${k}: ${categories[k]}`).join(" ");

  const sources = ["WIFI", "CELL", "GPS"];
  const ignoreDevices = [
    -210788392, // Android test
    // 898488237, // iPhone 12 Mini
    // -1695426, // Android Pixel 4
    // 816746503, // iPhone 12 Mini
  ];
  const selectedRecords = records.filter((r) => (
    (!r.source || sources.includes(r.source))
    && (!ignoreDevices.includes(r.deviceTag))
    && (r.accuracy && r.accuracy < 100)));
  const activityTitle = `location history ${records.length} records. (${selectedRecords.length} rendered) ${stats}`;

  return [{
    id: `google-lh:${firstReading.utcStartDateTime}`,
    name: activityTitle,
    points: selectedRecords,
    utcStartTime: firstReading.utcStartTime,
    localStartTime: DateTime.fromISO(firstReading.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
    raw: includeRaw ? records : null,
  }];
};

const parseGpx = (records, includeRaw, sourceName) => {
  const firstReading = records[0];
  if (!firstReading) {
    return [];
  }

  const points = records.filter((record) => record.type === "point");
  const waypoints = records.filter((record) => record.type === "waypoint");
  const activityTitle = `${sourceName} ${records.length} records `
    + `(${points.length} points, ${waypoints.length} waypoints).`;

  return [{
    id: `${sourceName}:${firstReading.utcStartDateTime}`,
    name: activityTitle,
    points: records,
    utcStartTime: firstReading.utcStartTime,
    localStartTime: DateTime.fromISO(firstReading.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
    raw: includeRaw ? records : null,
  }];
};

const parseGoogleTimeline = (response, includeRaw) => {
  const activities = [];
  response.forEach((record) => {
    if (record.placeVisit) {
      const { location } = record.placeVisit;
      let url = "";
      if (location.placeId) {
        url = `https://www.google.com/maps/place/?q=place_id:${location.placeId}`;
      }
      activities.push({
        id: `google:${record.utcStartDateTime}`,
        name: location.name,
        subtitle: location.address,
        url,
        utcStartTime: record.utcStartTime,
        localStartTime: DateTime.fromISO(record.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
        raw: record,
      });
    } else if (record.activitySegment) {
      let activityTitle = "Movement";
      const segment = record.activitySegment;
      if (segment.startLocation && segment.startLocation.name) {
        activityTitle = segment.startLocation.name;
      } else if (segment.startLocation && Object.keys(segment.startLocation).length > 0) {
        activityTitle = `${segment.startLocation.latitudeE7}, ${segment.startLocation.longitudeE7}`;
      } else if (segment.distance) {
        activityTitle = `Movement ${segment.distance}m`;
      }

      let points = [];
      if (segment.waypointPath) {
        points = points.concat(segment.waypointPath.waypoints);
      }

      activities.push({
        id: `google:${record.utcStartDateTime}`,
        name: activityTitle,
        points,
        utcStartTime: record.utcStartTime,
        localStartTime: DateTime.fromISO(record.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
        raw: includeRaw ? record : null,
      });
    }
  });
  return activities;
};

const parseMovesStoryline = (response, includeRaw) => {
  const activities = [];
  response.forEach((record) => {
    if (record.type === "place") {
      let placeName = record.place.name;
      if (!record.place.name) {
        placeName = `${record.place.location.lat},${record.place.location.lon}`;
      }
      activities.push({
        id: `moves ${record.utcStartDateTime}`,
        name: `Place: ${placeName}`,
        utcStartTime: record.utcStartTime,
        localStartTime: DateTime.fromISO(record.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
        raw: includeRaw ? record : null,
      });
    } else {
      let points = [];
      let duration = 0;
      if (record.activities) {
        record.activities.forEach((activity) => {
          points = points.concat(activity.trackPoints);
          duration += activity.duration;
        });
      }

      activities.push({
        id: `moves ${record.utcStartDateTime}`,
        name: `movement ${duration}`,
        points,
        utcStartTime: record.utcStartTime,
        localStartTime: DateTime.fromISO(record.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
        raw: includeRaw ? record : null,
      });
    }
  });
  return activities;
};

const parseAppleHealthActivity = (response, includeRaw) => {
  const activities = [];
  const firstReading = response[0];
  const uniqActivities = new Set();
  const counters = {};
  const counterUnits = {};

  response.forEach((record) => {
    uniqActivities.add(record.type);
    activities.push({
      id: `${record.type} ${record.startDate}`,
      name: `${record.type}: ${record.value} ${record.unit}`,
      utcStartTime: record.utcStartTime,
      localStartTime: DateTime.fromISO(record.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
      raw: record,
    });
    counters[record.type] = counters[record.type] ? counters[record.type] : 0;
    counters[record.type] += parseInt(record.value, 10);
    counterUnits[record.type] = record.unit;
  });

  let title = `${activities.length} activities of ${Array.from(uniqActivities).join(" ")}`;
  if (counters.StepCount) {
    title = `${counters.StepCount} steps`;
  }

  return [{
    id: `apple-health ${firstReading.utcStartDateTime}`,
    name: title,
    utcStartTime: firstReading.utcStartTime,
    localStartTime: DateTime.fromISO(firstReading.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
    steps: counters.StepCount || 0,
    raw: includeRaw ? activities : null,
  }];
};

const parseAppleHealthSleep = (response, includeRaw) => {
  const sleepEvents = [];
  const firstReading = response[0];
  let totalDurationSecs = 0;

  response.forEach((record) => {
    const startTime = DateTime.fromISO(record.utcStartDateTime);
    const endTime = DateTime.fromISO(record.utcEndDateTime);
    const durationSecs = endTime.diff(startTime, "seconds").seconds;
    totalDurationSecs += durationSecs;
    sleepEvents.push({
      id: `${record.type} ${record.startDate}`,
      name: `${record.type}: ${record.value} ${record.unit}`,
      utcStartTime: record.utcStartTime,
      localStartTime: DateTime.fromISO(record.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
      durationSecs,
      raw: includeRaw ? record : null,
    });
  });

  const durationString = Duration.fromMillis(totalDurationSecs * 1000).toFormat("h'h' m'm'");

  return [{
    id: `apple-health-sleep ${firstReading.utcStartDateTime}`,
    name: `sleep at ${firstReading.utcStartTime} for ${durationString}`,
    utcStartTime: firstReading.utcStartTime,
    localStartTime: DateTime.fromISO(firstReading.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
    durationSecs: totalDurationSecs,
    raw: includeRaw ? sleepEvents : null,
  }];
};

const parseJawboneSteps = (response, includeRaw) => {
  if (response.length < 1 || !response[0]) {
    return [];
  }

  const rawActivities = [];
  const firstReading = response[0];
  let totalSteps = 0;
  let totalDistance = 0;
  let totalCount = 0;

  response.forEach((record) => {
    totalSteps += record.steps;
    totalDistance += record.distance;
    totalCount += 1;

    rawActivities.push({
      id: `${record.time} ${record.startDate}`,
      name: `Steps ${record.steps} (${record.distance}m) at ${record.speed.toFixed(2)}`,
      utcStartTime: record.utcStartTime,
      localStartTime: DateTime.fromISO(record.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
      raw: record,
    });
  });

  return [{
    id: `jawbone-steps ${firstReading.startDate}`,
    name: `${totalSteps} steps (in ${totalCount} readings) (${totalDistance}m)`,
    utcStartTime: firstReading.utcStartTime,
    localStartTime: DateTime.fromISO(firstReading.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
    steps: totalSteps,
    raw: includeRaw ? rawActivities : null,
  }];
};

const parseJawboneSleep = (response, includeRaw) => {
  if (response.length < 1 || !response[0]) {
    return [];
  }

  const activities = [];
  response.forEach((record) => {
    activities.push({
      id: `sleep ${record.utcStartDateTime}`,
      name: "Sleep",
      utcStartTime: record.utcStartTime,
      localStartTime: DateTime.fromISO(record.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
      raw: includeRaw ? record : null,
    });
  });
  return activities;
};

const parseJawboneHeartRate = (response, includeRaw) => {
  if (response.length < 1 || !response[0]) {
    return [];
  }

  const firstReading = response[0];
  return [{
    id: `heartrate ${firstReading.utcStartDateTime}`,
    name: `Heartrate ${response.length} readings`,
    points: response,
    utcStartTime: firstReading.utcStartTime,
    localStartTime: DateTime.fromISO(firstReading.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
    raw: includeRaw ? response : null,
  }];
};

const parseFitbitSleep = (response, includeRaw) => {
  const activities = [];
  response.forEach((record) => {
    activities.push({
      id: `sleep ${record.utcStartDateTime}`,
      name: `Sleep ${record.level} for ${record.seconds}s`,
      level: record.level,
      utcStartTime: record.utcStartTime,
      localStartTime: DateTime.fromISO(record.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
      durationSecs: record.seconds,
      raw: includeRaw ? record : null,
    });
  });
  return activities;
};

const parseFitbitSteps = (response, includeRaw) => {
  if (response.length < 1) {
    return [];
  }

  const rawActivities = [];
  const firstReading = response[0];
  let totalSteps = 0;
  let totalCount = 0;

  response.forEach((record) => {
    totalSteps += record.steps;
    totalCount += 1;

    rawActivities.push({
      id: `fitbit-steps ${record.utcStartTime}`,
      name: `${record.steps} steps`,
      utcStartTime: record.utcStartTime,
      localStartTime: DateTime.fromISO(record.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
      raw: record,
    });
  });

  return [{
    id: `fitbit-steps ${firstReading.utcStartTime}`,
    name: `${totalSteps} steps (in ${totalCount} readings)`,
    utcStartTime: firstReading.utcStartTime,
    localStartTime: DateTime.fromISO(firstReading.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
    steps: totalSteps,
    raw: includeRaw ? rawActivities : null,
  }];
};

const parseFitbitHeartRate = (response, includeRaw) => {
  if (response.length < 1) {
    return [];
  }

  const firstReading = response[0];
  return [{
    id: `heartrate ${firstReading.utcStartDateTime}`,
    name: `Heartrate ${response.length} readings`,
    points: response,
    utcStartTime: firstReading.utcStartTime,
    localStartTime: DateTime.fromISO(firstReading.utcStartDateTime).toLocal().toFormat("HH:mm:ss"),
    raw: includeRaw ? response : null,
  }];
};

export const summarizeRecords = (filename, records, includeRaw = false) => {
  if (filename.match("foursquare-checkins")) {
    return parseFoursquareCheckins(records, includeRaw);
  }
  if (filename.match("applehealth-activity")) {
    return parseAppleHealthActivity(records, includeRaw);
  }
  if (filename.match("applehealth-sleep")) {
    return parseAppleHealthSleep(records, includeRaw);
  }
  if (filename.match("google-timeline")) {
    return parseGoogleTimeline(records, includeRaw);
  }
  if (filename.match("google-location-history")) {
    return parseGoogleLocationHistory(records, includeRaw);
  }
  if (filename.match("jawbone-steps")) {
    return parseJawboneSteps(records, includeRaw);
  }
  if (filename.match("jawbone-sleep")) {
    return parseJawboneSleep(records, includeRaw);
  }
  if (filename.match("jawbone-heartrate")) {
    return parseJawboneHeartRate(records, includeRaw);
  }
  if (filename.match("moves-storyline")) {
    return parseMovesStoryline(records, includeRaw);
  }
  if (filename.match("fitbit-sleep")) {
    return parseFitbitSleep(records, includeRaw);
  }
  if (filename.match("fitbit-steps")) {
    return parseFitbitSteps(records, includeRaw);
  }
  if (filename.match("fitbit-heartrate")) {
    return parseFitbitHeartRate(records, includeRaw);
  }
  if (filename.match("arc-timeline")) {
    return parseArcTimeline(records, includeRaw);
  }
  if (filename.match("silentlog")) {
    return parseGpx(records, includeRaw, "silentlog");
  }
  if (filename.match("mytracks")) {
    return parseGpx(records, includeRaw, "mytracks");
  }
  if (filename.match("tracesnow")) {
    return parseGpx(records, includeRaw, "tracesnow");
  }
  if (filename.match("slopes")) {
    return parseGpx(records, includeRaw, "slopes");
  }
  // console.log("Records not parsed", records);
  return records;
};
