/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
/* eslint-disable import/prefer-default-export */

import mergeWith from "lodash/mergeWith";
import fromPairs from "lodash/fromPairs";
import flatten from "lodash/flatten";
import mapValues from "lodash/mapValues";

import { summarizeRecords } from "./record-summary";

const recordsForPartialDay = async (firebase, db, hourIds) => {
  const recordsForDay = {};
  const records = await db.collection("timeline").where(firebase.firestore.FieldPath.documentId(), "in", hourIds).get();
  records.forEach((record) => {
    const data = record.data();
    if (!data) {
      return;
    }

    for (const source of Object.keys(data)) {
      if (source.startsWith("utc")) {
        continue;
      }
      if (!recordsForDay[source]) {
        recordsForDay[source] = [];
      }
      recordsForDay[source].push(data[source]);
    }
  });
  return recordsForDay;
};

export const aggregateRecordsForDay = async (firebase, db, utcStartTime) => {
  const hourIds = [];
  for (let h = 0; h < 24; h += 1) {
    const utcHour = utcStartTime.plus({ hour: h });
    const hourId = utcHour.toISO({
      suppressMilliseconds: true,
    });
    hourIds.push(hourId);
  }

  // Have to do the query in three batches because the query cannot spport more than 10 items.
  const firstThird = await recordsForPartialDay(firebase, db, hourIds.slice(0, 8));
  const secondThird = await recordsForPartialDay(firebase, db, hourIds.slice(8, 16));
  const lastThird = await recordsForPartialDay(firebase, db, hourIds.slice(16, 24));

  const mergeArrays = (obj, src) => {
    if (obj && obj.concat && src && src.concat) {
      return obj.concat(src);
    }
    return undefined;
  };

  // Merges the three thirds into a single dictionary with max three elements.
  const recordsForDay = mergeWith(firstThird, secondThird, lastThird, mergeArrays);
  // Merges each value of the dictionary into a single flat array.
  return fromPairs(Object.keys(recordsForDay).map((k) => ([k, flatten(recordsForDay[k])])));
};

export const summarizeDay = async (firebase, db, utcStartTime) => {
  const records = await aggregateRecordsForDay(firebase, db, utcStartTime);
  return mapValues(records, (v, k) => summarizeRecords(k, v, false));
};
