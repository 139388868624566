<template>
  <div class="day-hour-matrix">
    <div class="day-row">
      <div class="day-number"></div>
      <div class="locations country-cell"></div>
      <div
        v-for="hour in hours"
        :key="`h${hour.hour}`"
        :title="hour.timezones"
        class="hour-cell"
        :class="{ night: isNight(hour.hour) }"
      >
        {{ hour.local }}
      </div>
    </div>
    <div class="day-row">
      <div class="day-number">D</div>
      <div class="locations country-cell"></div>
      <div
        v-for="hour in hours"
        :key="`h${hour.hour}`"
        :title="hour.timezones"
        class="hour-cell"
        :class="{ night: isNight(hour.hour) }"
      >
        <span v-if="isNight(hour.hour)">🌔</span>
        <span v-else>☀️</span>
      </div>
    </div>
    <div v-for="day in daysMatrix" :key="day.day" class="day-row" :class="{weekend: day.isWeekend}">
      <div class="day-number"><a href="#" @click.prevent="$emit('day-selected', day.day)">{{ day.day }}</a></div>
      <div class="locations country-cell">
        {{ day.location.countryCode }}
      </div>
      <div class="hours">
        <div
          v-for="hour in day.hours"
          :key="hour.id"
          class="hour-cell"
          :title="hour.hour + ':00 ' + hour.id"
          :class="{
            night: isNight(hour.hour),
            selected: isHourSelected(day.day, hour.hour),
            event: hour.events && hour.events.length
          }"
          @click.prevent="selectDayHour(hour.id)"
        >
          <div class="emoji" :class="{ 'emoji-rotate': hour.emojiRotate }">
            {{ hour.emoji }}
          </div>
          <div
            v-if="hour.exists.hasTimeline"
            class="data-marker marker-timeline"
          ></div>
          <div
            v-if="hour.exists.hasMoves"
            class="data-marker marker-moves"
          ></div>
          <div
            v-if="hour.exists.hasJawboneSteps"
            class="data-marker marker-jawbone-steps"
          ></div>
          <div
            v-if="hour.exists.hasJawboneSleep"
            class="data-marker marker-jawbone-sleep"
          ></div>
          <div
            v-if="hour.exists.hasFitbitSteps"
            class="data-marker marker-fitbit-steps"
          ></div>
          <div
            v-if="hour.exists.hasAppleSleep"
            class="data-marker marker-apple-sleep"
          ></div>
           <div
            v-if="hour.exists.hasArcTimeline"
            class="data-marker marker-arc-timeline"
          ></div>
           <div
            v-if="hour.exists.hasSilentLog"
            class="data-marker marker-silentlog"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "src/layout";
@import "src/colors";

$cell-size: 1.5rem;

.data-marker {
  position: absolute;
  width: $cell-size;
  height: 1px;
  bottom: 0;
  left: 0;
  opacity: 0.5;
}

.marker-timeline {
  bottom: 0;
  left: 0.6rem;
  width: 0.6rem;
  border-bottom: 2px solid rgba(253, 37, 37, 1);
}

.marker-moves {
  bottom: 2px;
  left: 0.6rem;
  width: 0.6rem;
  border-bottom: 2px solid rgb(11, 109, 52);
}

.marker-jawbone-steps {
  bottom: 0;
  width: 0.6rem;
  border-bottom: 2px solid rgba(0, 0, 0, 1);
}

.marker-jawbone-sleep {
  bottom: 0;
  width: 0.6rem;
  border-bottom: 2px solid rgba(0, 0, 0, 1);
}

.marker-fitbit-steps {
  bottom: 0;
  left: 1.2rem;
  width: 0.6rem;
  border-bottom: 2px solid rgba(7, 177, 154, 1);
}

.marker-apple-sleep {
  bottom: 0;
  width: 0.6rem;
  border-bottom: 2px solid rgba(31, 31, 31, 1);
}

.marker-arc-timeline {
  bottom: 0;
  left: 1.2rem;
  width: 0.6rem;
  border-bottom: 2px solid rgb(181, 21, 230);
}

.marker-silentlog {
  bottom: 0;
  left: 1.2rem;
  width: 0.6rem;
  border-bottom: 2px solid rgb(36, 61, 202);
}

.emoji {
  // Force emoji to be shown with emoji fonts.
  font-family: 'Apple Color Emoji', 'Android Emoji', 'Segoe UI';
  font-weight: bold;
  font-size: 1rem;
}

.emoji-rotate {
  transform: rotate(90deg);
}

.days {
  font-size: 0.7rem;
}

.day-number {
  padding: 0rem 0.2rem;
  width: $cell-size;
  height: $cell-size;
  min-width: $cell-size;
  max-width: $cell-size;
}

.weekend .day-number {
  color: rgb(200, 0, 0);
  background: rgba(200, 0, 0, 0.2);
}

.day-row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.hours {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.hour-cell, .country-cell {
  position: relative;
  text-align: center;
  width: $cell-size;
  height: $cell-size;
  min-width: $cell-size;
  max-width: $cell-size;
  background: $color-very-very-light-grey;
  padding: 2px;
  margin: 1px;
}

.hour-cell.night {
  background:  $color-very-light-grey
}

.hour-cell.selected {
  border: 1px solid $color-black;
}

.hour-cell.event {
  background: $color-event-highlight;
}

.country-cell {
  background: none;
}

</style>

<script>
import { DateTime } from "luxon";

export default {
  props: {
    // In local time.
    year: { type: String, default: "2021" },
    month: { type: String, default: "04" },
    day: { type: String, default: "" },
    hour: { type: String, default: "" },
    daysMatrix: { type: Array, default() { return []; } },
    hours: { type: Array, default() { return []; } },
    timezone: { type: String, default: "UTC" },
    events: { type: Object, default() { return {}; } },
  },
  methods: {

    isNight(utcHour) {
      const nightHours = [19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6];
      const localHour = DateTime.utc(
        parseInt(this.year, 10),
        parseInt(this.month, 10),
        1,
        parseInt(utcHour, 10),
      ).hour;
      return nightHours.includes(localHour);
    },
    isHourSelected(day, hour) {
      return (day === this.day) && (hour === this.hour);
    },
    selectDayHour(isoDateTime) {
      this.$emit("day-hour-selected", isoDateTime);
    },
  },
};
</script>
