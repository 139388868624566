<template>
  <div class="static-map">
    <img v-if="staticMapsUrl" :src="staticMapsUrl" width="200" height="200" />
  </div>
</template>

<script>
import flatten from "lodash/flatten";
import max from "lodash/max";
import min from "lodash/min";

const normalizeE7 = (v) => v / 1e7;

const MAX_POINTS = 200;
const samplePoints = (points) => {
  if (points.length < MAX_POINTS) {
    return points;
  }

  const sampleRate = Math.ceil(points.length / MAX_POINTS);
  const sampled = points.filter((v, i) => (i % sampleRate === 0));
  return sampled;
};

export default {
  props: {
    sourceType: { type: String, default: "google" },
    width: { type: Number, default: 200 },
    records: { type: Array, default() { return []; } },
    points: { type: Array, default() { return []; } },
  },

  data() {
    return {
      apiKey: "AIzaSyDejONtf-8QcBrYYTLBf7z6jMhWLuO5A7w",
    };
  },

  computed: {
    paths() {
      let segments = [];
      if (this.sourceType === "google") {
        this.records.forEach((record) => {
          if (record.activitySegment) {
            const seg = record.activitySegment;
            const points = [];
            if (seg.startLocation.latitudeE7) {
              points.push([seg.startLocation.latitudeE7, seg.startLocation.longitudeE7]);
            }
            if (seg.waypointPath) {
              seg.waypointPath.waypoints.forEach((point) => {
                points.push([point.latE7, point.lngE7]);
              });
            }
            if (seg.endLocation.latitudeE7) {
              points.push([seg.endLocation.latitudeE7, seg.endLocation.longitudeE7]);
            }
            if (points.length) {
              segments.push(points);
            }
          }
        });
        segments = segments.map((points) => points.map((point) => point.map((v) => normalizeE7(v))));
      } else if (this.sourceType === "arc") {
        if (this.records && this.records[0]) {
          const points = this.records[0].points.map((p) => [p.lat, p.lng]);
          segments.push(samplePoints(points));
        }
      } else if (this.sourceType === "moves") {
        if (this.records && this.records[0] && this.records[0].points) {
          const points = this.records[0].points.map((p) => [p.lat, p.lon]);
          segments.push(samplePoints(points));
        }
      } else if (this.sourceType === "gpx") {
        if (this.records && this.records[0] && this.records[0].points) {
          const points = this.records[0].points
            .filter((p) => p.type === "point")
            .map((p) => [p.lat, p.lon]);
          segments.push(samplePoints(points));
        }
      }
      return segments;
    },

    places() {
      let places = [];
      if (this.sourceType === "google") {
        this.records.forEach((record) => {
          if (record.placeVisit) {
            const place = record.placeVisit;
            places.push([place.location.latitudeE7, place.location.longitudeE7]);
          }
        });
        places = places.map((point) => point.map((v) => normalizeE7(v)));
      } else if (this.sourceType === "moves") {
        this.records.forEach((record) => {
          if (record.raw.place && record.raw.place.location) {
            const { place } = record.raw;
            places.push([place.location.lat, place.location.lon]);
          }
        });
      } else if (this.sourceType === "gpx") {
        if (this.records && this.records[0] && this.records[0].points) {
          const points = this.records[0].points
            .filter((p) => p.type === "waypoint")
            .map((p) => [p.lat, p.lon]);
          places = places.concat(points);
        }
      } else if (this.sourceType === "foursquare") {
        this.records.forEach((record) => {
          const { location } = record.raw.venue;
          console.log("foursquare", record);
          if (location && location.lat && location.lng) {
            places.push([location.lat, location.lng]);
          }
        });
      }
      return places;
    },

    staticMapsUrl() {
      if (this.records.length < 1) {
        return "";
      }

      if (!this.places.length && !this.paths.length) {
        return "";
      }

      const params = new URLSearchParams();

      // Set to invalid values.
      let minLat = 90;
      let maxLat = -90;
      let minLng = 180;
      let maxLng = -180;
      let midLat = 0;
      let midLng = 0;

      if (this.places.length) {
        // TODO(find bounding box.)
        this.places.forEach((place) => {
          const [lat, lng] = place;
          minLat = Math.min(minLat, lat);
          maxLat = Math.max(maxLat, lat);
          minLng = Math.min(minLng, lng);
          maxLng = Math.max(maxLng, lng);

          params.append("markers", `color:0x000000ff|size:small|${place.join(",")}`);
        });
      }

      if (this.paths.length) {
        const allLat = flatten(this.paths.map((path) => path.map((point) => point[0])));
        const allLng = flatten(this.paths.map((path) => path.map((point) => point[1])));
        minLat = Math.min(min(allLat), minLat);
        maxLat = Math.max(max(allLat), maxLat);
        minLng = Math.min(min(allLng), minLng);
        maxLng = Math.max(max(allLng), maxLng);

        this.paths.forEach((path) => {
          params.append("path", `color:0x000000ff|weight:2|${path.map((latLng) => latLng.join(",")).join("|")}`);
        });
      }

      midLat = (minLat + maxLat) / 2;
      midLng = (maxLng + minLng) / 2;

      params.append("center", `${midLat},${midLng}`);
      params.append("zoom", this.zoomLevel(minLng, maxLng, minLat, maxLat));
      params.append("key", this.apiKey);
      params.append("scale", "2");
      params.append("size", `${this.width}x${this.width}`);
      params.append("maptype", "terrain");

      return `https://maps.googleapis.com/maps/api/staticmap?${params.toString()}`;
    },
  },
  methods: {

    zoomLevel(lngMin, lngMax, latMin, latMax) {
      const MAX_ZOOM = 20;

      // default zoom level if the diff is too small.
      if (Math.abs(lngMin - lngMax) < 0.0001) {
        return 12;
      }
      if (Math.abs(latMin - latMax) < 0.0001) {
        return 12; // default zoom level if the diff is too small.
      }

      const latDiff = latMax - latMin;
      const lngDiff = lngMax - lngMin;
      const maxDiff = (lngDiff > latDiff) ? lngDiff : latDiff;
      let zoomLevel = 1;
      if (maxDiff < 360 / (2 ** MAX_ZOOM)) {
        zoomLevel = MAX_ZOOM;
      } else {
        zoomLevel = -1 * ((Math.log(maxDiff) / Math.log(2)) - (Math.log(this.width) / Math.log(2)));
        if (zoomLevel < 1) { zoomLevel = 1; }
      }
      return zoomLevel;
    },
  },
};
</script>
