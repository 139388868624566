<template>
  <div class="trips">
    <header>
      <h1>data-house</h1>
      <section>
        <h3>User</h3>
        <sign-in-dialog
          @sign-in-completed="userDidSignIn"
          @sign-in-finished="userSignInDidFail"
        />
      </section>
    </header>
    <main>
      <section>
        <h1>trips</h1>
        <div class="trips-by-year">
          <div v-for="yearEvent in groupedByYear" :key="yearEvent[0]" class="year">
            <h3>{{yearEvent[0]}}</h3>
            <div v-for="event in yearEvent[1]" :key="event.id">
              {{eventEmoji(event)}} {{event.name}}
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<style lang="scss" scoped>
.trips {
  display: flex;
  flex-direction: row;
}

.trips-by-year {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .year {
    width: 200px;
  }

}
</style>

<script>
import { DateTime } from "luxon";
import groupBy from "lodash/groupBy";
import toPairs from "lodash/toPairs";
import orderBy from "lodash/orderBy";

import { db } from "../firebase";
import SignInDialog from "../components/SignInDialog.vue";

export default {
  components: {
    SignInDialog,
  },

  data() {
    return {
      user: null,
      events: [],
    };
  },

  computed: {
    groupedByYear() {
      if (this.events && this.events.length > 0) {
        return orderBy(toPairs(groupBy(this.events, this.eventYear)), (o) => o[0]);
      }
      return [];
    },
  },

  watch: {
    user(newUser) {
      if (newUser) {
        this.$bind(
          "events",
          db.collection("events").orderBy("utcStartDateTime", "asc"),
        );
      }
    },
  },

  mounted() {

  },

  methods: {

    userDidSignIn(user) {
      this.user = user;
    },

    userSignInDidFail() {
      this.user = null;
    },

    eventEmoji(event) {
      if (event.name.match("Work")) {
        return "💼";
      }
      return event.emoji;
    },

    eventYear(event) {
      const start = DateTime.fromISO(event.utcStartDateTime, { zone: "UTC" });
      const startYear = start.toFormat("yyyy");
      return startYear;
    },

  },
};
</script>
