import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import Trips from "../views/Trips.vue";
import Places from "../views/Places.vue";
import Connect from "../views/Connect.vue";
import NotFound404 from "../views/NotFound404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: {
      default: true,
      demo: true,
    },
  },
  {
    path: "/trips",
    name: "Trips",
    component: Trips,
    props: {
      default: true,
    },
  },
  {
    path: "/places",
    name: "Places",
    component: Places,
    props: {
      default: true,
    },
  },
  {
    path: "/dash",
    name: "Dashboard",
    component: Dashboard,
    props: {
      default: true,
      demo: false,
    },
  },
  {
    path: "/connect",
    name: "Connect",
    component: Connect,
  },
  { path: "*", component: NotFound404 },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
