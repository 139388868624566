<template>
  <div class="sleep-chart">
  </div>
</template>

<script>
import * as d3 from "d3";
import { DateTime } from "luxon";

export default {
  props: {
    records: { type: Array, default() { return []; } },
    height: { type: String, default: "50" },
    width: { type: String, default: "200" },
    hour: { type: String, default: "00" },
  },

  data() {
    return {
      fitbitSleepLevels: ["wake", "restless", "light", "deep", "rem"],
    };
  },

  computed: {
    startTime() {
      return this.createDateTime(`${this.hour}:00:00`);
    },
    endTime() {
      return this.createDateTime(`${this.hour}:59:59`);
    },
  },

  mounted() {
    if (this.records.length > 0) {
      this.draw();
    }
  },

  methods: {
    createDateTime(hhmmss) {
      return new Date(`2000-01-01T${hhmmss}`);
    },

    draw() {
      for (const child of this.$el.children) {
        this.$el.removeChild(child);
      }

      const margin = { bottom: 2 };

      const svg = d3.create("svg")
        .attr("viewBox", [0, 0, this.width, this.height])
        .attr("width", this.width)
        .attr("height", this.height);

      const x = d3.scaleTime().domain([this.startTime, this.endTime]).range([0, this.width]);
      const y = d3.scaleLinear().domain([0, 4]).range([0, this.height - margin.bottom]);

      this.records.forEach((o) => {
        console.log(o);
        let level = 3;
        if (o.level) {
          level = this.fitbitSleepLevels.indexOf(o.level);
        }
        const date = this.createDateTime(o.utcStartTime);
        let { durationSecs } = o;
        if (o.seconds) {
          durationSecs = o.seconds;
        }
        const endDate = DateTime.fromJSDate(date).plus({ seconds: durationSecs }).toJSDate();
        const xOffset = x(date);
        const xWidth = x(endDate) - xOffset;
        const yOffset = y(level);
        console.log(date, xOffset, level, yOffset);
        svg.append("g")
          .attr("transform", `translate(${xOffset} ${this.height - yOffset - margin.bottom})`)
          .append("rect")
          .attr("fill", "black")
          .attr("width", xWidth)
          .attr("height", yOffset);
      });

      svg.append("line")
        .attr("x1", 0)
        .attr("y1", this.height - 1)
        .attr("x2", this.width)
        .attr("y2", this.height - 1)
        .attr("stroke", "grey")
        .attr("strokewidth", 0.5);

      this.$el.appendChild(svg.node());
    },
  },
};
</script>
