export const hourString = (v) => v.toString().padStart(2, 0);
export const dayString = (v) => v.toString().padStart(2, 0);
export const monthString = (v) => v.toString().padStart(2, 0);
export const yearString = (v) => v.toString();

export const hourNumber = (v) => parseInt(v, 10);
export const dayNumber = (v) => parseInt(v, 10);
export const monthNumber = (v) => parseInt(v, 10);
export const yearNumber = (v) => parseInt(v, 10);

export const isoString = (dt) => dt.toISO({ suppressSeconds: true, suppressMilliseconds: true });
