<template>
  <div class="steps-chart">
  </div>
</template>

<script>
import * as d3 from "d3";
import { DateTime } from "luxon";

export default {
  props: {
    records: { type: Array, default() { return []; } },
    height: { type: String, default: "50" },
    width: { type: String, default: "200" },
    hour: { type: String, default: "00" },
    format: { type: String, default: "" },
    valueKey: { type: String, default: "steps" },
  },

  computed: {
    startTime() {
      return this.createDateTime(`${this.hour}:00:00`);
    },
    endTime() {
      return this.createDateTime(`${this.hour}:59:59`);
    },
  },

  mounted() {
    if (this.records.length > 0) {
      this.draw();
    }
  },

  methods: {
    createDateTime(hhmmss) {
      return new Date(`2000-01-01T${hhmmss}`);
    },

    draw() {
      for (const child of this.$el.children) {
        this.$el.removeChild(child);
      }

      let { records } = this;
      if (this.format === "applehealth") {
        records = this.records.filter((o) => o.type === "StepCount");
      }

      const margin = { bottom: 2, top: 10 };

      const svg = d3.create("svg")
        .attr("viewBox", [0, 0, this.width, this.height])
        .attr("width", this.width)
        .attr("height", this.height);

      // const yMax = d3.max(records, (d) => d[this.valueKey]);
      const x = d3.scaleTime()
        .domain([this.startTime, this.endTime])
        .range([0, this.width]);
      const y = d3.scaleLinear()
        .domain([0, 100])
        .range([0, this.height - margin.bottom - margin.top]);
      const bandwidth = Math.floor(this.width / 60 - 0.5);

      records.forEach((o) => {
        const date = this.createDateTime(o.utcStartTime);
        const xOffset = x(date);
        if (o.utcEndTime) {
          const endDate = this.createDateTime(o.utcEndTime);
          const durationM = DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(date), "minutes").minutes;
          const xWidth = x(endDate) - x(date);
          const yOffset = o[this.valueKey] / durationM;
          // console.log("durationM", durationM, yOffset);
          svg.append("g")
            .attr("transform", `translate(${xOffset} ${this.height - yOffset - margin.bottom})`)
            .append("rect")
            .attr("fill", "black")
            .attr("width", xWidth)
            .attr("height", yOffset);
        } else {
          const yOffset = y(o[this.valueKey]);

          svg.append("g")
            .attr("transform", `translate(${xOffset} ${this.height - yOffset - margin.bottom})`)
            .append("rect")
            .attr("fill", "black")
            .attr("width", bandwidth)
            .attr("height", yOffset);
        }
      });

      svg.append("line")
        .attr("x1", 0)
        .attr("y1", this.height - 1)
        .attr("x2", this.width)
        .attr("y2", this.height - 1)
        .attr("stroke", "grey")
        .attr("strokewidth", 0.5);

      this.$el.appendChild(svg.node());
    },
  },
};
</script>
