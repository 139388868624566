<template>
  <div class="home">
    <header>
      <h1>auth</h1>
      <a href="/fitbit/callback">Fitbit Auth</a>
      <a href="/tripit/callback">Tripit Auth</a>
      <a
        href="https://foursquare.com/oauth2/authenticate?client_id=CH3SDZEGVJ3XSJGEVXZ3LXCPEQ00BREX4SEOAH105IQ5BTCE&response_type=code&redirect_uri=https://liquidx-data-house.web.app/foursquare/callback"
        >Sign in to Foursquare</a
      >
    </header>
  </div>
</template>

<style lang="scss" scoped>
@import "src/layout";
@import "src/colors";

header {
  flex-grow: 0;
  width: 200px;
  min-width: 200px;
  max-height: 100vh;
  font-size: 0.9rem;
  padding: 5rem 1rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 1.2rem;
    a {
      text-decoration: none;
    }
  }
  .signin-button {
    color: white;
    background: black;
  }
}
</style>

<script>
export default {
  name: "Home",
};
</script>
