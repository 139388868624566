import Vue from "vue";
import { firestorePlugin } from "vuefire";
import { app } from "./firebase";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

Vue.use(firestorePlugin);

Vue.prototype.$firebase = app;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
