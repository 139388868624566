<template>
  <div class="signin-dialog">
    <div v-if="!user" class="signin">
      <input
        v-model="signInEmail"
        type="email"
        class="email"
        name="email"
        placeholder="email"
      />
      <input
        v-model="signInPassword"
        type="password"
        class="password"
        name="password"
      />
      <button class="signin-button" @click="signIn">Sign In</button>
    </div>
    <div v-if="user">
      {{ user.email }} (<a href="#" @click.prevent="signOut">Sign out</a>)
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "src/colors";

input {
  padding: 0.2rem 0.5rem;
  margin: 0.2rem;
  border: 1px solid $color-input-border;
}
</style>

<script>
export default {
  data() {
    return {
      user: null,
      signInEmail: "",
      signInPassword: "",
    };
  },

  mounted() {
    this.$firebase.auth().onAuthStateChanged((user) => {
      this.user = user;
      this.$emit("sign-in-completed", user);
    });
  },

  methods: {
    signIn() {
      this.$firebase
        .auth()
        .signInWithEmailAndPassword(this.signInEmail, this.signInPassword)
        .then((userCredential) => {
          this.user = userCredential.user;
          this.$emit("sign-in-completed", userCredential.user);
        })
        .catch((error) => {
          this.$emit("sign-in-failed", error);
        });
    },
    signOut() {
      this.$firebase.auth().signOut().then(() => {
        this.$emit("sign-in-failed");
      });
    },
  },
};
</script>
